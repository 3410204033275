
.quotesItemContainer {
    padding-bottom: 10px;
}
.quotes {
    border:1px solid #e5e1e1;
    padding: 15px;
    border-radius: 6px;
}
.quotesCaption {
    font-size: 20px;
}
.quotesAuthor {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
}
.quotesShareContainer {
    display: flex;
    justify-content:space-between;
    flex-direction: row;
    padding-top: 5px;
    align-items: center;
}
.quotesCopyContainer {

}