
.menuTabsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: center;
}
.tabsContainer {
    padding: 10px;
    border:1px solid grey;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.tabs {
    display: flex;
}