
.quoteOfTheDayContainer {
    margin-bottom: 15px;
    display: 'flex';
    border-bottom: 1px solid #e5e1e1;
    padding-bottom: 20px;
}

.quoteOfTheDayItemContainer {
    text-align: center;
}

.quoteOfTheDayItem {
    font-size: 26px;
    text-align: center;
}

.quoteOfTheDayCaption {
    text-decoration: overline;
    padding-bottom: 10px;
}