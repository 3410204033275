
.header-container {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .header-logo {
        height: '100px';
        width: '100px';
    }  
    .googlePlaystoreButton {
        display: flex;
        justify-content: flex-end;
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .header-container {
        justify-content: center;
        .header-logo {
            height: '100px';
            width: '100px';
        }               
    }   
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }